import React, { useReducer, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../auth/authContext";
import InvoiceContext from "../invoice/invoiceContext";
import PaymentContext from "./paymentContext";
import paymentReducer from "./paymentReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_PAYMENT,
  DELETE_PAYMENT,
  CLEAR_PAYMENTS,
  SET_CURRENT_PAYMENT,
  CLEAR_CURRENT_PAYMENT,
  UPDATE_PAYMENT,
  FILTER_PAYMENTS,
  CLEAR_FILTER_PAYMENT,
  PAYMENT_ERROR,
  GET_PAYMENTS,
  STATUS_CHANGE_PAYMENT,
  FILTER_PAYMENTS_BY_SHOP,
  SET_LOADING_PAYMENTS,
} from "../types";

const PaymentState = (props) => {
  const invoiceContext = useContext(InvoiceContext);
  const { clearInvoices, getInvoicesByStatus } = invoiceContext;
  const navigate = useNavigate();
  const initialState = {
    payments: null,
    currentPayment: null,
    filteredPayment: null,
    error: null,
    loadingPayments: false,
  };

  const [state, dispatch] = useReducer(paymentReducer, initialState);
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const getPayments = async () => {
    try {
      const res = await axios.get(`/api/payment/`);
      dispatch({ type: GET_PAYMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
    }
  };

  const getPaymentById = async (id) => {
    try {
      const res = await axios.get(`/api/payment/${id}`);
      dispatch({ type: SET_CURRENT_PAYMENT, payload: res.data });
      if (user.role === "Office Admin" || user.role === "Office User") {
        navigate("/company/payment/view");
      } else {
        navigate("/shop/payment/view");
      }
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
    }
  };

  const addPayment = async (payment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/payment", { payment }, config);
      dispatch({ type: ADD_PAYMENT, payload: res.data });
      clearInvoices();
      getInvoicesByStatus("Approved");
      setLoadingPayments(false);
      toast.success(`Payment is created`);
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
      toast.error(`Payment ${err}`);
    }
  };

  const uploadPayment = async (payment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/payment/upload", { payment }, config);
      toast.success(`Payment is created`);
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
      toast.error(`Payment ${err}`);
    }
  };

  const deletePayment = async (payment) => {
    try {
      const res = await axios.delete(`/api/payment/delete/${payment._id}`);
      dispatch({ type: DELETE_PAYMENT, payload: payment._id });
      toast.success(`Payment is deleted`);
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
      toast.error(`Payment ${err}`);
    }
  };

  const updatePayment = async (payment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/payment/update/${payment._id}`, payment, config);
      dispatch({ type: UPDATE_PAYMENT, payload: res.data });
      toast.success(`Payment is updated`);
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
      toast.error(`Payment ${err}`);
    }
  };

  const updateHaulPayment = async (payment) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/payment/haul/update/${payment._id}`, payment, config);
      dispatch({ type: UPDATE_PAYMENT, payload: res.data });
      toast.success(`Payment is updated`);
    } catch (err) {
      dispatch({ type: PAYMENT_ERROR });
      toast.error(`Payment ${err}`);
    }
  };

  const clearPayments = () => {
    dispatch({ type: CLEAR_PAYMENTS });
  };

  const setCurrentPayment = (payment) => {
    dispatch({ type: SET_CURRENT_PAYMENT, payload: payment });
  };

  const clearCurrentPayment = () => {
    dispatch({ type: CLEAR_CURRENT_PAYMENT });
  };

  const filterPayments = (text) => {
    dispatch({ type: FILTER_PAYMENTS, payload: text });
  };

  const clearFilterPayment = () => {
    dispatch({ type: CLEAR_FILTER_PAYMENT });
  };

  const statusChange = (payment) => {
    dispatch({ type: STATUS_CHANGE_PAYMENT, payload: payment });
  };
  const setLoadingPayments = (bool) => {
    dispatch({ type: SET_LOADING_PAYMENTS, payload: bool });
  };

  return (
    <PaymentContext.Provider
      value={{
        payments: state.payments,
        currentPayment: state.currentPayment,
        filteredPayment: state.filteredPayment,
        error: state.error,
        loadingPayments: state.loadingPayments,
        getPayments,
        addPayment,
        deletePayment,
        setCurrentPayment,
        clearCurrentPayment,
        updatePayment,
        filterPayments,
        clearFilterPayment,
        clearPayments,
        statusChange,
        getPaymentById,
        setLoadingPayments,
        uploadPayment,
        updateHaulPayment,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

export default PaymentState;
