import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import ShopContext from "../../../../context/shop/shopContext";
import AuthContext from "../../../../context/auth/authContext";
import PopConfirm from "./PopConfirm";
import ShopFilter from "../../../../context/invoice/ShopFilter";
import GlobalInvoiceSearch from "../../../../context/invoice/GlobalInvoiceSearch";
import { Table, Tag } from "antd";
import axios from "axios";

const CompanyNewInvoices = () => {
  const navigate = useNavigate();
  // const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const invoiceContext = useContext(InvoiceContext);
  const {
    getInvoicesByStatus,
    invoices,
    getInvoiceById,
    clearInvoices,
    filteredInvoices,
    searchedInvoice,
    invoiceLoading,
    // getAdjustedInvoices,
    adjustedInvoices,
  } = invoiceContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let theInvoices = invoices;

  if (currentShop) {
    theInvoices =
      invoices &&
      invoices.filter((invoice) => {
        return invoice.shop._id === currentShop._id;
      });
  }

  useEffect(() => {}, [filteredInvoices, invoices, searchedInvoice]);

  useEffect(() => {
    getInvoicesByStatus("New");
    return () => {
      clearInvoices();
      // cancelToken.cancel();
    };
  }, []);

  const handleViewInvoice = (invoice) => {
    getInvoiceById(invoice._id);
  };

  // if (!localStorage.token) {
  //   console.log("there is not a token");
  // } else {
  //   console.log("there is a token");
  // }

  const allInvoices = adjustedInvoices && invoices && adjustedInvoices.concat(invoices);

  const columns = [
    // i want another column for the row number
    {
      title: "Row",
      width: "5%",
      align: "center",
      render: (text, record, rowIndex) => <span>{rowIndex + 1}</span>,
    },
    {
      title: `Shop`,
      render: (text, record) => <span>{record.shop.name}</span>,
    },
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color={record.status === "Adjusted" ? "red" : "geekblue"}>
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },

    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record.order &&
            record.order.grandTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },

    // {
    //   title: `Standard Total Cost`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.standardTotalCost.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Standard Profit $`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order.standardProfitDollars.toLocaleString('en-US', {
    //         style: 'currency',
    //         currency: 'USD',
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Standard Profit %`,
    //   render: (text, record) => <span>{record.order && record.order.standardProfitPercent.toFixed(2)}%</span>,
    // },

    {
      title: "Actions",
      width: "15%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <div className="flexcontainer">
            {record.status === "New" ? (
              <Fragment>
                <PopConfirm
                  record={record}
                  msg={"Approve Invoice?"}
                  btnText={"Approve"}
                  theStatus={"Approved"}
                  myClasses={"btn btn-xs btn-outline-success"}
                />
                <PopConfirm
                  record={record}
                  msg={"Deny Invoice?"}
                  btnText={"Deny"}
                  theStatus={"Denied"}
                  myClasses={"btn btn-xs btn-outline-danger"}
                />
              </Fragment>
            ) : record.status === "Adjusted" ? (
              <Fragment>
                <PopConfirm
                  record={record}
                  msg={"Approve Invoice?"}
                  btnText={"Approve"}
                  theStatus={"Approved"}
                  myClasses={"btn btn-xs btn-outline-success"}
                />
                <PopConfirm
                  record={record}
                  msg={"Deny Invoice?"}
                  btnText={"Deny"}
                  theStatus={"Denied"}
                  myClasses={"btn btn-xs btn-outline-danger"}
                />
              </Fragment>
            ) : (
              <div></div>
            )}
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={(e) => handleViewInvoice(record, e)}>
              View
            </button>
          </div>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12 text-center">
          {filteredInvoices && (
            <h4>
              {filteredInvoices.length > 0
                ? `${currentShop && currentShop.name} has ${filteredInvoices.length} New Invoices`
                : "No New Invoices"}
            </h4>
          )}
          {!filteredInvoices && (
            <h4>{theInvoices && theInvoices.length > 0 ? theInvoices.length + " New Invoices" : "No New Invoices"}</h4>
          )}
          {adjustedInvoices && (
            <h4>
              {adjustedInvoices && adjustedInvoices.length > 0
                ? adjustedInvoices.length + " Adjusted Invoices"
                : "No Adjusted Invoices"}
            </h4>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <ShopFilter status={"New"} />
        </div>

        <div className="col-md-2"></div>

        <div className="col-md-6 mt-4">
          <GlobalInvoiceSearch />
        </div>
      </div>

      <div className="row">
        <Table
          rowClassName={() => "hover-row"}
          loading={!invoiceLoading && (searchedInvoice || filteredInvoices || theInvoices) ? false : true}
          columns={columns}
          dataSource={searchedInvoice || filteredInvoices || theInvoices}
          rowKey="_id"
          onChange={onChange}
          pagination={{ defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ["10", "20", "30", "40", "50"] }}
        />
      </div>
    </div>
  );
};

export default CompanyNewInvoices;
