import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import { Table } from "antd";
import axios from "axios";

const PaidInvoices = () => {
  const navigate = useNavigate();
  const invoiceContext = useContext(InvoiceContext);
  const { getInvoicesByStatus, invoices, getInvoiceById, clearInvoices, deleteInvoice, invoiceLoading } = invoiceContext;
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());

  useEffect(() => {
    getInvoicesByStatus("Paid");
    return () => {
      clearInvoices();
      // cancelToken.cancel();
    };
  }, []);

  const handleViewInvoice = (invoice) => {
    getInvoiceById(invoice._id);
  };

  const handleDeleteInvoice = (invoice) => {
    deleteInvoice(invoice);
  };

  const columns = [
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record.order.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    // {
    //   title: `Total Cost`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.shopTotalCost.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Profit $`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.shopProfitDollars.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Profit %`,
    //   render: (text, record) => <span>{record.order && record.order.shopProfitPercent.toFixed(2)}%</span>,
    // },

    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-secondary" type="button" onClick={(e) => handleViewInvoice(record, e)}>
            View Invoice
          </button>
          {/* <button className="btn btn-xs btn-outline-danger" type="button" onClick={(e) => handleDeleteInvoice(record)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div className="row">
      <div className="col text-center">
        {/* <h4>{invoices && invoices.length > 0 ? invoices.length + " Paid Invoices" : "No Paid Invoices"}</h4> */}
        <h4>{invoices && invoices.length > 0 ? invoices.length + " Paid Invoices" : "No Paid Invoices"}</h4>

        {/* <OrderFilter /> */}
        <Table
          rowClassName={() => "hover-row"}
          className="mt-2"
          pagination={{ pageSize: 200 }}
          loading={(!invoiceLoading && invoices) || !invoiceLoading ? false : true}
          columns={columns}
          dataSource={invoices && invoices}
          rowKey="_id"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default PaidInvoices;
