import React, { useState, Fragment, useContext, useEffect } from "react";
import { Table, Input, Button, Space, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import OrderContext from "../../../../context/order/orderContext";
import GlobalOrderSearch from "../../../../context/order/GlobalOrderSearch";

const CompanyFinishedOrders = () => {
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();

  const orderContext = useContext(OrderContext);

  const {
    getOrders,
    orders,
    getOrderById,
    loadingOrders,
    clearOrders,
    searchedOrder,
    setPagination,
    pagination,
    setLoadingOrders,
    clearSearchedOrder,
  } = orderContext;

  useEffect(() => {
    clearSearchedOrder();
    clearOrders();
    getOrders("Finished");
  }, []);

  const handleViewShopsOrders = (order) => {
    getOrderById(order._id);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Clear Filters
          </Button>
          <Button
            type="danger"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ fontSize: "20px", color: filtered ? "white" : "white" }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => searchInput.select(), 100);
      // }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm({ closeDropdown: false });

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColor = (p) => {
    if (
      p.alerts &&
      p.alerts.filter((alert) => {
        if (alert.accepted === false) {
          return alert;
        }
      }).length > 0
    )
      return "orange";
    // if (p.quantity < p.orderPoint) return 'orange';

    return "";
  };

  const getTextColor = (p) => {
    if (
      p.alerts &&
      p.alerts.filter((alert) => {
        if (alert.accepted === false) {
          return alert;
        }
      }).length > 0
    )
      return "red";
    return "black";
  };

  const columns = [
    {
      title: "Shop",
      width: "50%",
      sorter: (a, b) => b.shop.name > a.shop.name,
      defaultSortOrder: ["descend"],
      render(text, record) {
        return {
          props: {
            style: {
              background: getColor(record),
              color: getTextColor(record),
            },
          },
          children: <div>{record.shop.name}</div>,
        };
      },
    },
    {
      title: "Serial #",
      key: "serialNumber",
      width: "30%",
      dataIndex: "serialNumber",
      ...getColumnSearchProps("serialNumber"),
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },
    {
      title: "Edit",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <span>
          {
            <Fragment>
              {/* <DeleteOutlined onClick={() => handleRemove(p.slug, user.token)} className='text-danger pl-2 pr-4' /> */}
              {/* <EyeOutlined onClick={() => handleViewShopsOrders(record)} className='text-info pl-2 ' style={{ fontSize: '20px' }} /> */}
              <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShopsOrders(record)}>
                View
              </button>
            </Fragment>
          }
        </span>
      ),
    },
  ];

  // const handleTableChange = (newPagination, filters, sorter) => {
  //   setLoadingOrders(true);
  //   getOrdersByCategory("Finished", newPagination);
  //   setPagination(newPagination);
  // };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4">
          <h4>{orders && orders.length + " Finished Orders"}</h4>
        </div>
        <div className="col-md-8">
          <GlobalOrderSearch />
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        pagination={pagination}
        // onChange={handleTableChange}
        loading={(!loadingOrders && orders) || (!loadingOrders && searchedOrder) ? false : true}
        columns={columns}
        dataSource={(searchedOrder && searchedOrder) || (orders && orders)}
        rowKey="_id"
      />
      {/* <button className='btn btn-xs btn-outline-primary' type='button' onClick={() => handleUpdateBulkOrdersToInvoiced()}>
          Update Orders (Invoiced)
        </button> */}
    </Fragment>
  );
};

export default CompanyFinishedOrders;
