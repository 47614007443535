import React, { useState, useContext, useEffect } from "react";
import OrderContext from "../../../../context/order/orderContext";
import { Table, Tag, Modal } from "antd";

const DuplicateOrders = () => {
  const orderContext = useContext(OrderContext);
  const { loadingOrders, orders, getDuplicateOrders, deleteDupOrder } = orderContext;

  useEffect(() => {
    getDuplicateOrders();
  }, []);

  useEffect(() => {
    console.log("this is the orders", orders);
  }, [orders]);

  const handleDeleteOrder = (order) => {
    Modal.confirm({
      title: `Are you sure you want to Delete this ${order.status} Order.`,
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteDupOrder(order);
      },
    });
  };

  const columns = [
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key: "orders",
      render: (orders) => (
        <ul>
          {orders.map((order) => (
            <li>
              <span style={{ marginRight: "10px" }}>{order.shop}</span>
              {/* <span style={{ marginRight: "10px" }}>{order.status}</span> */}
              <Tag style={{ marginRight: "10px" }} color="volcano">
                {order && order.status}
              </Tag>
              <button
                className="btn btn-xs btn-outline-danger mb-2"
                type="button"
                onClick={(e) => handleDeleteOrder(order)}
                style={{ marginLeft: "10px" }}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <Table
      rowClassName={() => "hover-row"}
      pagination={false}
      loading={!loadingOrders ? false : true}
      columns={columns}
      dataSource={orders && orders}
      rowKey="_id"
    />
  );
};

export default DuplicateOrders;
