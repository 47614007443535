import React, { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import UserNav from "./components/layout/nav/UserNav";
import { ToastContainer } from "react-toastify";
import { Layout, Modal } from "antd";
import { Form, Input, Button, Select, Typography, Space } from "antd";

import AuthContext from "./context/auth/authContext";
import ShopContext from "./context/shop/shopContext";
import Spinner from "./components/layout/Spinner";

import Alerts from "./components/layout/Alerts";
import AnnouncementAlert from "./components/pages/shop/announcements/AnnouncementAlert";
import packageInfo from "../package.json";
import { toast } from "react-toastify";

import UpdateApp from "./UpdateApp";
import ErrorBoundary from "./ErrorBoundry";

import mondaySdk from "monday-sdk-js";

const { Content, Sider, Footer } = Layout;
const { Title } = Typography;
const monday = mondaySdk();

const MyLayout = () => {
  const authContext = useContext(AuthContext);
  const { user, loading, isAuthenticated } = authContext;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  // console.log("This is the user", user);
  // console.log("This is shop", currentShop);

  const [context, setContext] = useState(null);

  useEffect(() => {
    if (user?.role === "Shop Admin") {
      getShop();
    }
    monday.get("context").then((res) => {
      setContext(res.data);
    });
  }, []);

  useEffect(() => {
    if (!loading && (!user || !isAuthenticated)) {
      navigate("/login");
    }
    // You can add a cleanup function here if needed, but returning a JSX component is not correct.
  }, [user, isAuthenticated, loading, navigate]);

  // If loading, render spinner
  if (loading) {
    return <Spinner />;
  }

  const HelpModal = ({ visible, onClose }) => {
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [priority, setPriority] = useState("");
    const [groupId, setGroupId] = useState(null);
    const boardId = 7190482807; // Ensure this is the correct board ID

    useEffect(() => {
      const apiKey =
        "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjM5NTM3NzYxNSwiYWFpIjoxMSwidWlkIjoyODQ3NDUxMywiaWFkIjoiMjAyNC0wOC0wOVQyMDozOToxNy4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTEzODk0MzYsInJnbiI6InVzZTEifQ.EGAf9VTw25MlHDgW-4tGEHPxc1iZ4nCcj95mt_AcQn0";
      monday
        .api(
          `query {
      boards(ids: ${boardId}) {
        groups {
          id
          title
        }
        columns {
          id
          title
          type
        }
      }
    }`,
          { token: apiKey } // Pass the API key here
        )
        .then((res) => {
          // console.log("API Response:", res.data); // Log the full response
          const group = res.data.boards[0].groups.find((group) => group.title === "Shed-Pro");
          const columns = res.data.boards[0].columns;
          // console.log("Available columns:", columns);
          // console.log("Found Group:", group); // Log the found group
          setGroupId(group?.id);
        })
        .catch((error) => {
          console.error("Error fetching group ID:", error); // Log any errors
        });
    }, [boardId]); // Only run once after mounting, since boardId is static

    const handleSubmit = () => {
      const apiKey =
        "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjM5NTM3NzYxNSwiYWFpIjoxMSwidWlkIjoyODQ3NDUxMywiaWFkIjoiMjAyNC0wOC0wOVQyMDozOToxNy4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTEzODk0MzYsInJnbiI6InVzZTEifQ.EGAf9VTw25MlHDgW-4tGEHPxc1iZ4nCcj95mt_AcQn0";

      if (!groupId) {
        console.error("Group ID not set, cannot submit form."); // Ensure groupId is set
        return;
      }

      const itemData = {
        board_id: boardId,
        group_id: groupId,
        item_name: user.role === "Shop Admin" ? currentShop.name : "Stor-Mor", // Conditional item_name based on user role
        column_values: JSON.stringify({
          text: user.email,
          long_text: description,
          text__1: serialNumber,
          status_1: priority,
        }),
      };

      const mutation = `mutation {
    create_item (
      board_id: ${itemData.board_id},
      group_id: "${itemData.group_id}",
      item_name: "${itemData.item_name}",
      column_values: "${itemData.column_values.replace(/\"/g, '\\"')}"
    ) {
      id
    }
  }`;

      monday
        .api(mutation, { token: apiKey })
        .then((res) => {
          if (res.errors) {
            console.error("API returned errors:", res.errors);
          } else {
            console.log("Item created:", res.data.create_item.id);
            toast.success("Ticket submitted successfully!");
            onClose(); // Close the modal on successful submission
          }
        })
        .catch((error) => {
          toast.error("Failed to submit the ticket. Please try again.");
          console.error("Error creating item:", error.response || error.message || error);
        });
    };

    return (
      <Modal
        title="Help & Support"
        visible={visible}
        onOk={handleSubmit} // You can also attach submit to the "Ok" button
        onCancel={onClose}
        footer={null} // Remove default footer buttons
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          style={{
            maxWidth: "600px",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Title level={3} style={{ textAlign: "center" }}>
            Submit a Ticket
          </Title>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {/* Uncomment if you want to include an email field */}
            {/* <Form.Item label="Email" required>
          <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item> */}
            <Form.Item label="Description" required>
              <Input.TextArea rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Item>
            <Form.Item label="Serial Number (if needed)">
              <Input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
            </Form.Item>
            <Form.Item label="Priority" required>
              <Select value={priority} onChange={(value) => setPriority(value)} placeholder="Select priority">
                <Select.Option value="High">High</Select.Option>
                <Select.Option value="Medium">Medium</Select.Option>
                <Select.Option value="Low">Low</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%", // Full width to match input fields
                  padding: "15px", // Increased padding for a thicker button
                  height: "50px", // Set a fixed height for consistency
                  lineHeight: "20px", // Set line-height to center the text vertically
                  borderRadius: "20px", // Rounded corners for a modern look
                  backgroundColor: "#1890ff", // Primary color
                  borderColor: "#1890ff", // Match border color to background
                  fontSize: "16px", // Slightly larger font for better readability
                  transition: "background-color 0.3s, transform 0.3s", // Smooth transition effects
                  display: "flex", // Use flexbox to center the text vertically and horizontally
                  alignItems: "center", // Vertically center the text
                  justifyContent: "center", // Horizontally center the text
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#40a9ff")} // Lighter shade on hover
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#1890ff")} // Return to original color on mouse leave
                onClick={(e) => (e.currentTarget.style.transform = "scale(0.98)")} // Slight press effect on click
              >
                Submit
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Layout>
        <Navbar />

        <Layout>
          <Sider width={200} className="site-layout-background" theme="light">
            <UserNav />
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                // minHeight: '90vh',
                height: "85vh",
                overflow: "auto",
              }}
            >
              <Alerts />
              <UpdateApp />
              <AnnouncementAlert />
              <ToastContainer />
              {/* <ErrorBoundary> */}
              <Outlet />
              {/* </ErrorBoundary> */}
            </Content>
            <Footer style={{ textAlign: "center", position: "relative" }}>
              Shed Pro ©2022 Created by Tru-Tech - V: {packageInfo.version}
              <Button
                type="primary"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  borderRadius: "20px",
                  padding: "0 20px", // Adjust padding to focus on left and right
                  cursor: "pointer",
                  height: "40px", // Set a fixed height
                  lineHeight: "40px", // Match line-height to height to center text vertically
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={showModal}
              >
                Help & Support
              </Button>
              <HelpModal visible={isModalVisible} onClose={handleClose} />
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </Fragment>
  );
};

export default MyLayout;
