import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu, Layout, Badge, Avatar } from "antd";
import { HomeOutlined, MenuOutlined, BellOutlined } from "@ant-design/icons";

import CommentContext from "../../context/comment/commentContext";
import AuthContext from "../../context/auth/authContext";
import ShopContext from "../../context/shop/shopContext";
import CompanyContext from "../../context/company/companyContext";

// import NotificationBadge from "react-notification-badge";
// import { Effect } from "react-notification-badge";

const { Header } = Layout;
const { SubMenu } = Menu;

const Navbar = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState();
  const [counter, setCounter] = useState(0);

  const commentContext = useContext(CommentContext);
  const { comments, setNotification, getComments, updateComment, setCommentDrawerVisible } = commentContext;
  const authContext = useContext(AuthContext);
  const { logout, user, setIsAuthenticated, isAuthenticated } = authContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const companyContext = useContext(CompanyContext);
  const { company, getCompany } = companyContext;

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = localStorage.token && parseJwt(localStorage.token);

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
      setIsAuthenticated(false);
    }
    getShop();
    getCompany();
    getComments();
  }, []);

  useEffect(() => {}, [comments]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(counter + 1);
      getComments();
    }, 3600000);

    return () => clearInterval(intervalId);
  }, [counter]);

  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  const handleShowComments = () => {
    // setCommentDrawerVisible(true);
    navigate("/shop/messages");
  };
  const handleShowCompanyComments = () => {
    // setCommentDrawerVisible(true);
    navigate("/company/messages");
  };

  //  const handleViewOrder = (order, notif) => {
  //    setNotification(comments.filter((n) => n !== notif));
  //    getOrderById(order._id);
  //    updateComment(notif);
  //  };

  const authLinks = (
    <Header theme="light" className="myheader ">
      <div className="myheaderitem">
        <>Shed-Pro </>
      </div>

      <div className="myheaderitemtwo">
        <div className="myheaderitemtwoItems">
          {user && user.name} -{" "}
          <span className="userName">
            (
            {user &&
              (user.role === "Shop User" || user.role === "Shop Admin"
                ? currentShop && currentShop.name
                : company && company.name)}
            )
          </span>
        </div>
      </div>

      {user && (user.role === "Office User" || user.role === "Office Admin") && (
        <Menu
          className="myheaderitem"
          theme="light"
          onClick={handleMenuChange}
          selectedKeys={[current]}
          mode="horizontal"
          // style={{ width: "100px" }}
        >
          {comments && (
            <Menu.Item key="comments">
              <a href="#" onClick={handleShowCompanyComments}>
                <Badge count={comments && comments.filter((comment) => comment.read !== true).length}>
                  <Avatar
                    style={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                    }}
                    icon={<BellOutlined />}
                  />
                </Badge>
              </a>
            </Menu.Item>
          )}
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/company/home"></Link>
          </Menu.Item>
          <SubMenu key="sub22" icon={<MenuOutlined />}>
            <Menu.Item key="settings">
              <Link to="/company/settings">Settings</Link>
            </Menu.Item>
            {user && user.name === "Matt Schmidt" && (
              <Fragment>
                {/* <Menu.Item key="admin">
                  <Link to="/admin">Admin</Link>
                </Menu.Item> */}
                <Menu.Item key="logs">
                  <Link to="/company/admin/logs">Logs</Link>
                </Menu.Item>
              </Fragment>
            )}
            <Menu.Item key="logout">
              <a onClick={onLogout} href="#!">
                <span className="hide-sm">Logout</span>
              </a>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key='admin' icon={<SettingOutlined />}>
          <Link to='/admindashboard'>Admin</Link>
        </Menu.Item> */}
        </Menu>
      )}

      {user && (user.role === "Shop User" || user.role === "Shop Admin" || user.role === "Builder") && (
        <Menu
          className="myheaderitem"
          theme="light"
          onClick={handleMenuChange}
          selectedKeys={[current]}
          mode="horizontal"
          // style={{ width: "100px" }}
        >
          {comments && (
            <Menu.Item key="comments">
              <a href="#" onClick={handleShowComments}>
                <Badge count={comments && comments.filter((comment) => comment.read !== true).length}>
                  <Avatar
                    style={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                    }}
                    icon={<BellOutlined />}
                  />
                </Badge>
              </a>
            </Menu.Item>
          )}
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/shop/home"></Link>
          </Menu.Item>
          <SubMenu key="sub22" icon={<MenuOutlined />}>
            <Menu.Item key="settings">
              <Link to="/shop/settings">Settings</Link>
            </Menu.Item>

            {user && user.name === "Matt Schmidt" && (
              <Fragment>
                <Menu.Item key="admin">
                  <Link to="/admin">Admin</Link>
                </Menu.Item>
                <Menu.Item key="logs">
                  <Link to="/shop/logs">Logs</Link>
                </Menu.Item>
              </Fragment>
            )}
            <Menu.Item key="logout">
              <a onClick={onLogout} href="#!">
                <span className="hide-sm">Logout</span>
              </a>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key='admin' icon={<SettingOutlined />}>
          <Link to='/admindashboard'>Admin</Link>
        </Menu.Item> */}
        </Menu>
      )}
    </Header>
  );

  const guestLinks = (
    <Header theme="light" className="myheader">
      <Menu onClick={handleMenuChange} mode="horizontal" theme="light">
        <Menu.Item key="login">
          <Link to="/login">Login</Link>
        </Menu.Item>
        {/* <Menu.Item key='register'>
            <Link to='/register'>Login</Link>
          </Menu.Item> */}
      </Menu>
    </Header>
  );

  return <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>;
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "Shed Pro",
};

export default Navbar;
