import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import { useNavigate } from "react-router-dom";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllShedLabors = () => {
  const navigate = useNavigate();
  const shedLaborContext = useContext(ShedLaborContext);
  const shedModelContext = useContext(ShedModelContext);
  const { getShopShedLaborsByModel, shedLabors, setCurrentShedLabor } = shedLaborContext;
  const { currentShedModel } = shedModelContext;
  useEffect(() => {
    currentShedModel && getShopShedLaborsByModel(currentShedModel);
  }, []);

  const columns = [
    {
      title: "Width",
      width: "20%",
      render: (record) => <Fragment>{record.width}</Fragment>,
    },

    {
      title: "Length",
      width: "20%",
      render: (record) => <Fragment>{record.length}</Fragment>,
    },

    {
      title: "Total Labor",
      width: "20%",
      render: (record) => (
        <Fragment>
          {record.shopValues.totalLaborCost.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Fragment>
      ),
    },
    {
      title: "Standard Labor",
      width: "20%",
      render: (record) => (
        <Fragment>
          {record.standardTotalLaborCost.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Fragment>
      ),
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShedLabor(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewShedLabor = (shedLabor) => {
    setCurrentShedLabor(shedLabor);
    navigate("/shop/shed/labor/edit");
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
          <h4 className=" text-center">{currentShedModel.name}</h4>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={shedLabors}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllShedLabors;
