import React, { useEffect, useContext } from "react";
import Alerts from "../../layout/Alerts";
import OrderContext from "../../../context/order/orderContext";
import InvoiceContext from "../../../context/invoice/invoiceContext";
import { Spin } from "antd";

import InvoicesChartOne from "./charts/InvoicesChartOne";
import OrderChartOne from "./charts/OrderChartOne";
import StockTicker from "./StockTicker";

const CompanyHome = () => {
  const orderContext = useContext(OrderContext);
  const { chartOrders, getOrdersForChart } = orderContext;

  const invoiceContext = useContext(InvoiceContext);
  const { chartInvoices, getInvoiceForChart } = invoiceContext;
  useEffect(() => {
    getInvoiceForChart();
    getOrdersForChart();
  }, []);

  return (
    <div>
      <Alerts />
      <div className="row">
        <div className="col-md-6">{!chartOrders ? <Spin size="large" /> : <OrderChartOne orders={chartOrders} />}</div>
        <div className="col-md-6">{!chartInvoices ? <Spin size="large" /> : <InvoicesChartOne invoices={chartInvoices} />}</div>
        {/* <StockTicker /> */}
      </div>
    </div>
  );
};

export default CompanyHome;
