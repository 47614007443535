import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import PaymentContext from "../../../../context/payment/paymentContext";
import ShopContext from "../../../../context/shop/shopContext";
import AuthContext from "../../../../context/auth/authContext";
import ShopFilter from "../../../../context/invoice/ShopFilter";
import NewPaymentModal from "./payments/NewPaymentModal";
import { Table, Tag } from "antd";
import GlobalInvoiceSearch from "../../../../context/invoice/GlobalInvoiceSearch";
import { DatePicker, Modal, Spin } from "antd";
import axios from "axios";

const dateFormat = "MM/DD/YYYY";
const CompanyApprovedInvoices = () => {
  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());

  const invoiceContext = useContext(InvoiceContext);
  const {
    getInvoicesByStatus,
    invoices,
    filteredInvoices,
    clearInvoices,
    searchedInvoice,
    invoiceLoading,
    setCutOffDate,
    cutOffDate,
    updateInvoice,
    deleteInvoice,
    getInvoiceById,
  } = invoiceContext;

  const paymentContext = useContext(PaymentContext);
  const { loadingPayments } = paymentContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let theInvoices = invoices;

  if (currentShop) {
    theInvoices =
      invoices &&
      invoices.filter((invoice) => {
        return invoice.shop._id === currentShop._id;
      });
  }

  useEffect(() => {}, [filteredInvoices, invoices, searchedInvoice]);

  useEffect(() => {
    getInvoicesByStatus("Approved");
    return () => {
      clearInvoices();
      // cancelToken.cancel();
    };
  }, []);

  const handleViewInvoice = (invoice) => {
    getInvoiceById(invoice._id);
  };

  const handleDateChange = (date) => {
    setCutOffDate(date);
  };

  const handleChangeStatusToApproved = (record) => {
    Modal.confirm({
      title: "The Status of this Invoice will change to New.",
      okText: "Yes",
      okType: "confirm",
      onOk: () => {
        let newRecord = {
          ...record,
          status: "New",
        };
        updateInvoice(newRecord);
      },
    });
  };

  const handleDeleteInvoice = (record) => {
    Modal.confirm({
      title: "Are you sure you want to Delete this Invoice.",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteInvoice(record);
      },
    });
  };

  const columns = [
    {
      title: `Shop`,
      sorter: (a, b) => b.shop.name > a.shop.name,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.shop.name}</span>,
    },
    {
      title: `Invoice Number`,
      render: (text, record) => <span>{record.invoiceNumber}</span>,
    },
    {
      title: `Invoiced Date`,
      sorter: (a, b) => b.invoicedDate > a.invoicedDate,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.invoicedDate && new Date(record.invoicedDate).toLocaleDateString()}</span>,
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },
    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record.order &&
            record.order.grandTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    // {
    //   title: `Standard Total Cost`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order &&
    //         record.order.standardTotalCost.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Standard Profit $`,
    //   render: (text, record) => (
    //     <span>
    //       {record.order.standardProfitDollars.toLocaleString('en-US', {
    //         style: 'currency',
    //         currency: 'USD',
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   title: `Standard Profit %`,
    //   render: (text, record) => <span>{record.order && record.order.standardProfitPercent.toFixed(2)}%</span>,
    // },

    {
      title: "Actions",
      // width: "15%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          {/* <div className='flexcontainer'> */}
          {/* <PopConfirm
              record={record}
              msg={'Approve Invoice?'}
              btnText={'Approve'}
              theStatus={'Approved'}
              myClasses={'btn btn-xs btn-outline-success'}
            />
            <PopConfirm record={record} msg={'Deny Invoice?'} btnText={'Deny'} theStatus={'Denied'} myClasses={'btn btn-xs btn-outline-danger'} /> */}

          <button className="btn btn-xs btn-outline-primary" type="button" onClick={(e) => handleViewInvoice(record, e)}>
            View
          </button>

          <button
            className={user.viewOnly ? "viewOnly" : "btn btn-xs btn-outline-info ms-4"}
            disabled={user.viewOnly}
            // className="btn btn-xs btn-outline-info ms-4"
            type="button"
            onClick={(e) => handleChangeStatusToApproved(record, e)}
          >
            Change Status
          </button>
          {user.name === "Matt Schmidt" && (
            <button className="btn btn-xs btn-outline-danger ms-4" type="button" onClick={(e) => handleDeleteInvoice(record, e)}>
              Delete
            </button>
          )}
          {/* </div> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const onSelectChange = (selectedRowKeys, record) => {
    // console.log('selectedRowKeys record: ', record);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedInvoices(record);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCreatePayment = () => {
    setModalVisible(true);
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <Fragment>
        {loadingPayments ? (
          <Spin size="large" />
        ) : (
          <NewPaymentModal
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setSelectedInvoices}
          />
        )}
      </Fragment>
      <div className="row">
        <div className="col-md-3 mb-2">
          <button
            className={user.viewOnly ? "viewOnly" : "btn  btn-outline-success float-start"}
            // disabled={user.viewOnly}
            // className="btn  btn-outline-success float-start"
            type="button"
            onClick={handleCreatePayment}
            disabled={!hasSelected || user.viewOnly}
          >
            Create Payment
          </button>
        </div>

        <div className="col-md-1">
          <div className="row">
            <label className="">Cut Off Date</label>
            <DatePicker format={dateFormat} onChange={(val) => handleDateChange(val)} value={cutOffDate} />
          </div>
        </div>

        <div className="col-md-4 text-center">
          {filteredInvoices && (
            <h4>
              {filteredInvoices.length > 0
                ? `${currentShop && currentShop.name} has ${filteredInvoices.length} Approved Invoices`
                : "No Approved Invoices"}
            </h4>
          )}
          {!filteredInvoices && (
            <h4>{theInvoices && theInvoices.length > 0 ? theInvoices.length + " Approved Invoices" : "No Approved Invoices"}</h4>
          )}
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <ShopFilter status={"Approved"} />
        </div>

        <div className="col-md-2">
          {/* <div className="row">
            <label>Cut Off Date</label>
            <DatePicker format={dateFormat} onChange={(val) => handleDateChange(val)} />
          </div> */}
        </div>

        <div className="col-md-6 mt-4">
          <GlobalInvoiceSearch />
        </div>
      </div>

      <div className="row">
        <Table
          rowClassName={() => "hover-row"}
          loading={!invoiceLoading && (searchedInvoice || filteredInvoices || theInvoices) ? false : true}
          columns={columns}
          dataSource={searchedInvoice || filteredInvoices || theInvoices}
          rowKey="_id"
          onChange={onChange}
          rowSelection={rowSelection}
          pagination={{ defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ["10", "20", "30", "40", "50"] }}
        />
      </div>
    </div>
  );
};

export default CompanyApprovedInvoices;
