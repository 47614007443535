import { useForm, useFieldArray } from "react-hook-form";
import React, { useEffect, useContext } from "react";
import BaseStyleContext from "../../../../context/baseStyle/baseStyleContext";
import { Table, Input, Select, Button, Card, Space, Typography, Layout } from "antd";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const BaseStyles = () => {
  const baseStyleContext = useContext(BaseStyleContext);
  const { getBaseStyles, baseStyles, updateBaseStyle } = baseStyleContext;
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      baseStyles: [
        {
          name: "",
          buildPercent: { value: "", type: "" },
          roofPercent: { value: "", type: "" },
          trussCost: { value: "", type: "" },
          paintCost: { value: "", type: "" },
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: "baseStyles" });

  const onSubmit = (data) => {
    updateBaseStyle(data);
  };

  useEffect(() => {
    getBaseStyles();
  }, []);

  useEffect(() => {
    if (baseStyles) {
      const formattedBaseStyles = baseStyles.map((style) => {
        return {
          name: style.name,
          doorType: style.doorType,
          doorCost: style.standardValues?.doorCost,
          buildPercent: {
            value: style.standardValues.buildPercent?.value,
            type: style.standardValues.buildPercent?.type,
          },
          roofPercent: {
            value: style.standardValues.roofPercent?.value,
            type: style.standardValues.roofPercent?.type,
          },
          trussCost: {
            value: style.standardValues.trussCost?.value,
            type: style.standardValues.trussCost?.type,
          },
          paintCost: {
            value: style.standardValues.paintCost?.value,
            type: style.standardValues.paintCost?.type,
          },
          _id: style._id,
        };
      });
      reset({ baseStyles: formattedBaseStyles });
    }
  }, [baseStyles]);

  const cardStyle = {
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    marginBottom: "24px",
  };

  const inputStyle = {
    width: "100%",
    marginBottom: "8px",
  };

  const labelStyle = {
    fontWeight: "bold",
    marginBottom: "4px",
    display: "block",
  };

  const percentageInputProps = {
    type: "number",
    step: "0.01",
    min: "0",
    max: "100",
    style: inputStyle,
  };

  return (
    <Layout>
      <Content style={{ padding: "24px", backgroundColor: "#f0f2f5" }}>
        <Card style={cardStyle}>
          <Title level={3}>Base Styles</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Table
              dataSource={fields}
              pagination={false}
              rowKey="id"
              bordered
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  render: (text, record, index) => (
                    <Input {...register(`baseStyles[${index}].name`, { required: true })} defaultValue={record.name} />
                  ),
                },
                {
                  title: "Door Type",
                  dataIndex: "doorType",
                  render: (text, record, index) => (
                    <Select
                      {...register(`baseStyles[${index}].doorType`, { required: true })}
                      defaultValue={record.doorType}
                      style={{ width: "100%" }}
                    >
                      <Option value="">Select</Option>
                      <Option value="tall">Tall</Option>
                      <Option value="short">Short</Option>
                    </Select>
                  ),
                },
                {
                  title: "Door Cost",
                  dataIndex: "doorCost",
                  render: (text, record, index) => (
                    <Input {...register(`baseStyles[${index}].doorCost`, { required: true })} defaultValue={record.doorCost} />
                  ),
                },
                {
                  title: "Build Percent",
                  children: [
                    {
                      title: "Value",
                      dataIndex: "buildPercent.value",
                      render: (text, record, index) => (
                        <Input
                          {...register(`baseStyles[${index}].buildPercent.value`, { required: true })}
                          defaultValue={record.buildPercent.value}
                        />
                      ),
                    },
                    {
                      title: "Type",
                      dataIndex: "buildPercent.type",
                      render: (text, record, index) => (
                        <Select
                          {...register(`baseStyles[${index}].buildPercent.type`, { required: true })}
                          defaultValue={record.buildPercent.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">Select</Option>
                          <Option value="percent">Percent</Option>
                          <Option value="piece">Piece</Option>
                        </Select>
                      ),
                    },
                  ],
                },
                {
                  title: "Roof Percent",
                  children: [
                    {
                      title: "Value",
                      dataIndex: "roofPercent.value",
                      render: (text, record, index) => (
                        <Input
                          {...register(`baseStyles[${index}].roofPercent.value`, { required: true })}
                          defaultValue={record.roofPercent.value}
                        />
                      ),
                    },
                    {
                      title: "Type",
                      dataIndex: "roofPercent.type",
                      render: (text, record, index) => (
                        <Select
                          {...register(`baseStyles[${index}].roofPercent.type`, { required: true })}
                          defaultValue={record.roofPercent.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">Select</Option>
                          <Option value="percent">Percent</Option>
                          <Option value="piece">Piece</Option>
                        </Select>
                      ),
                    },
                  ],
                },
                {
                  title: "Truss Cost",
                  children: [
                    {
                      title: "Value",
                      dataIndex: "trussCost.value",
                      render: (text, record, index) => (
                        <Input
                          {...register(`baseStyles[${index}].trussCost.value`, { required: true })}
                          defaultValue={record.trussCost.value}
                        />
                      ),
                    },
                    {
                      title: "Type",
                      dataIndex: "trussCost.type",
                      render: (text, record, index) => (
                        <Select
                          {...register(`baseStyles[${index}].trussCost.type`, { required: true })}
                          defaultValue={record.trussCost.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">Select</Option>
                          <Option value="percent">Percent</Option>
                          <Option value="piece">Piece</Option>
                        </Select>
                      ),
                    },
                  ],
                },
                {
                  title: "Paint Cost",
                  children: [
                    {
                      title: "Value",
                      dataIndex: "paintCost.value",
                      render: (text, record, index) => (
                        <Input
                          {...register(`baseStyles[${index}].paintCost.value`, { required: true })}
                          defaultValue={record.paintCost.value}
                        />
                      ),
                    },
                    {
                      title: "Type",
                      dataIndex: "paintCost.type",
                      render: (text, record, index) => (
                        <Select
                          {...register(`baseStyles[${index}].paintCost.type`, { required: true })}
                          defaultValue={record.paintCost.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">Select</Option>
                          <Option value="percent">Percent</Option>
                          <Option value="piece">Piece</Option>
                        </Select>
                      ),
                    },
                  ],
                },
              ]}
            />
            <div style={{ textAlign: "right", marginTop: 16 }}>
              <Button type="primary" htmlType="submit">
                Update Base Styles
              </Button>
            </div>
          </form>
        </Card>
      </Content>
    </Layout>
  );
};

export default BaseStyles;
